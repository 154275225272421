import {ViewRow} from "./ViewRow";

export const MOTCommentViewRow = (props) => {

    return(
        <ViewRow divClasses={"col-12 h-2rem"} title={props.title} titleClasses={"col-8 floatLeft"} titleLabelClasses={"text-l"}
                 value={props.value} valueClasses={"col-4 floatRight"}
                 valueLabelClasses={"floatRight font-bold text-lg"}/>
    )

}
