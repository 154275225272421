import {useEffect, useState} from "react";
import {Button} from "primereact/button";
import {MOTService} from "../services/MOTService";
import {useLocation, useHistory} from "react-router-dom";
import {DATE_FORMAT} from "../constants/Constants";
import dateFormat from "dateformat";
import {MOTTestView} from "../components/MOTTestView";
import {VehicleViewRow} from "../components/VehicleViewRow";
import {InputText} from "primereact/inputtext";
import {Image} from "primereact/image";
import { Helmet } from 'react-helmet';
export const MOT = () => {

    const [registration, setRegistration] = useState("");
    const [result, setResult] = useState({motTests: []});
    const [loading, setLoading] = useState(false);

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const reg = params.get('registration');
        if (reg && !loading) {
            setRegistration(reg);
            handleCheck(reg);
        }
    }, [location]);

    const handleRegistrationChange = (e) => {
        const reg = e.target.value.toUpperCase();
        setRegistration(reg);
    }

    const handleCheck = (reg) => {
        setLoading(true);
        const motService = new MOTService();
        motService.getCheckMOT(reg)
            .then(response => {response.data.motTests.sort((a, b) => new Date(b.completedDate) - new Date(a.completedDate));
                setResult(response.data);
                const currentSearchParams = new URLSearchParams(location.search);
                currentSearchParams.set('registration', reg);
                history.replace({
                    pathname: location.pathname,
                    search: '?' + currentSearchParams.toString()
                });
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }
    const handleKeyPress = (e) => {
        console.log("before if");
        if (e.key === "Enter"){
            console.log("into if");
            handleCheck(registration);
        }
        console.log("after if");
    }
    return (
        <div>
            <Helmet>
                <title>Car File - MOT Check</title>
                <meta name="description" content="Check a vehicle MOT tests history and current status"/>
                <meta name="keywords" content="MOT, check, Car File, vehicle"/>
                <meta name="author" content="Car File LTD"/>
                <meta property="og:title" content="Car File - MOT Check"/>
                <meta property="og:description" content="Check a vehicle MOT tests history and current status"/>
                <meta property="og:image" content="https://example.com/image.jpg"/>
                <meta property="og:url" content="https://example.com/my-page"/>
                <meta name="twitter:title" content="Car File - MOT Check"/>
                <meta name="twitter:description" content="Check a vehicle MOT tests history and current status"/>
                <meta name="twitter:image" content="https://example.com/image.jpg"/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <div className="form-holder">
                <div className="flex justify-content-center">
                    <div className="card align-content-center">
                        <div className="mx-auto pb-0">
                            <Image className="wid10" alt="Car File MOT Check"
                                   src="assets/images/Car and File v5.7 - Black.png"
                                   width="100"/>
                            <h1 className="mt-0 pt-0">MOT Check</h1>
                        </div>
                        <div className="p-inputgroup flex-1 wid60 xl mx-auto">
                            <span
                                className="p-inputgroup-addon bg-yellow-400 border-yellow-400 border-2 text-white text-2xl font-bold">GB</span>
                            <InputText className="text-2xl font-bold border-yellow-400 border-2" placeholder="Registration"
                                       maxLength="7" onKeyPress={handleKeyPress}
                                       value={registration} onChange={(e) => handleRegistrationChange(e)}/>
                        </div>
                        <Button className="mt-3 px-8" icon="pi pi-check" loading={loading} label="Check"
                                onClick={() => handleCheck(registration)}/>
                        <hr/>
                    </div>
                </div>
            </div>
            <div className="align-content-center" style={{display: result.registration ? "flex" : "none"}}>
                <div className={`col-12 sm:flex-none md:flex-none lg:flex mx-auto`}>
                    <div className="card sm:col-12 md:col-12 lg:col-6 xl:col-4 block mx-auto">
                        <h3>Vehicle identity</h3>

                        <VehicleViewRow title="Registration" value={result.registration}/>
                        <VehicleViewRow title="Make" value={result.make}/>
                        <VehicleViewRow title="Model" value={result.model}/>
                        <VehicleViewRow title="Manufacture Year"
                                        value={result.firstUsedDate ? result.firstUsedDate.substring(0, 4) : result.manufactureYear}/>
                        {/*<VehicleViewRow title="Manufacture Year"*/}
                        {/*                value={dateFormat(result.firstUsedDate ?*/}
                        {/*                    result.firstUsedDate.replace(".", "-") :*/}
                        {/*                    result.manufactureYear, "yyyy")}/>*/}
                        <VehicleViewRow title="Fuel Type" value={result.fuelType}/>
                        <VehicleViewRow title="MOT Expiry date"
                                        value={dateFormat(result.motTestExpiryDate, DATE_FORMAT)}/>
                        <VehicleViewRow title="Primary Colour" value={result.primaryColour}/>
                    </div>
                    <div className="card sm:col-12 md:col-12 lg:col-6 xl:col-6 block mx-auto">
                        <h3>MOT Tests</h3>
                        {result.motTests.map((test) => (
                            <MOTTestView test={test}/>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
