import React from 'react';
import packageJson from '../package.json';

export const AppFooter = (props) => {

    return (
        <div className="layout-footer">
            <img src={props.layoutColorMode === 'light' ? 'assets/images/Car and File v5.7 - Black.png' : 'assets/images/logo-white.svg'} alt="Logo" height="25" className="mr-2" />
            <span><strong>Car File {packageJson.version}</strong> by </span>
            <span className="font-medium ml-2"><a href="https://happycompany.ltd" target="_blank" rel="noreferrer">Happy Company Ltd</a></span>
        </div>
    );
}
