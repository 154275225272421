import {ACCESS_TOKEN_URL, BASE_DOMAIN, CLIENT_ID, CLIENT_SECRET} from '../constants/Constants';
import axios from "axios";
import {encode as base64_encode} from 'base-64';

    const register = (givenName, familyName, email, password, birthDate) => {
        return axios.post(BASE_DOMAIN + "user/add", {
            givenName,
            familyName,
            email,
            password,
            birthDate,
        });
    };

    async function login(username, password) {
        await axios
            .post( ACCESS_TOKEN_URL + "?grant_type=password&username="+username+"&password="+password, {},
                {headers : {"Authorization": "Basic " +
                       base64_encode(CLIENT_ID + ":" + CLIENT_SECRET)}}
            )
            .then((response) => {
                if (response.data) {
                    localStorage.setItem("access_token", response.data.access_token);
                    localStorage.setItem("refresh_token", response.data.refresh_token);
                    localStorage.setItem("expires_in", response.data.expires_in);
                    localStorage.setItem("scope", response.data.scope);
                    localStorage.setItem("jti", response.data.jti);
                }
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const logout = () => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("expires_in");
        localStorage.removeItem("scope");
        localStorage.removeItem("jti");
        return window.location.href = `/login`;
    };

    const getCurrentUser = () => {
        const token = localStorage.getItem("access_token");
        if (token == null){
            return null;
        } else {
            return JSON.parse(atob(token?.split('.')[1]));
        }
    };

    const getLocalRefreshToken = () => {
        return localStorage.getItem("refresh_token");
    }

    const getLocalAccessToken = () => {
        return localStorage.getItem("access_token");
    }

    const updateLocalAccessToken = (newToken) => {
        localStorage.setItem("access_token", newToken);
    }

    const updateLocalRefreshToken = (newToken) => {
        localStorage.setItem("refresh_token", newToken);
    }

    const setUserToken = (newToken) => {
        const user = JSON.parse(localStorage.getItem("user"));
        user.token = newToken;
        localStorage.setItem("user", JSON.stringify(user));
    };

    function updateStoredUser(user){
        const tempUser = JSON.parse(localStorage.getItem('user'));
        tempUser.firstName = user.firstName;
        tempUser.midName = user.midName;
        tempUser.lastName = user.lastName;
        localStorage.setItem('user', JSON.stringify(tempUser));
    }

export default {
    register,
    login,
    logout,
    getCurrentUser,
    setUserToken,
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    updateLocalRefreshToken,
    updateStoredUser,
};