import { BASE_DOMAIN } from '../constants/Constants';
import authHeader from "./AuthHeader";
import api from "./api";

export class TaxService {
    getTaxList(){
        return api.get( BASE_DOMAIN + "api/tax/list", {
            headers:  authHeader()
        });
    }
}