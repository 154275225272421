import {VehicleService} from "../services/VehicleService";
import {Button} from "primereact/button";
import React, {useEffect, useState, useRef} from "react";
import '../assets/css/DataView.css';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import Flag from "react-world-flags";
import {useTranslation} from "react-i18next";

const VehicleDataTable = () => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [vehicleList, setVehicleList] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const isMounted = useRef(false);
    useEffect(() => {
        setTimeout(() => {
            isMounted.current = true;
            setLoading(true);
            const vehicleService = new VehicleService();
            vehicleService.getVehicleList()
                .then(response => {
                    setVehicleList(response.data);
                    setLoading(false);
                });
        }, 1000);
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, [loading]);

    const renderHeader = () => {
        return (
            <div>
                <span className="p-input-icon-left floatLeft addButton">
                    <i className="pi pi-plus" />
                    <Button title="Add vehicle" icon="pi pi-plus"/>
                </span>
                <div className="flex justify-content-center align-items-center">
                    <h5 className="m-0">{t('your_vehicles')}</h5>
                </div>
            </div>
        )
    }

    const layout = 'list';
    const header = renderHeader();

    const plateBody = (rowData) => {
        return (
            <React.Fragment>
                <span className="plate">
                    <Flag code={rowData.plateCountry} height={12}
                          alt={rowData.plateCountry} title={rowData.plateCountry}/> | <strong>{rowData.plate}</strong>
                </span>
            </React.Fragment>
        );
    }

    return (
        <div>
            <DataTable value={vehicleList} layout={layout} paginator rows={10} loading={loading} header={header}
                       selectionMode="single" dataKey="vehicleId"
                       selection={selectedVehicle} onSelectionChange={e => setSelectedVehicle(e.value)}>
                <Column  header={t('plate')} sortable filter filterPlaceholder="Search by plate"
                    body={plateBody}/>
                <Column field="vin" header={t('vin')} sortable filter filterPlaceholder="Search by VIN" />
                <Column field="engine" header={t('engine_number')} sortable filter filterPlaceholder="Search by Engine Number"/>
                <Column field="make" header={t('make')} sortable filter filterPlaceholder="Search by make" />
                <Column field="model" header={t('model')} sortable filter filterPlaceholder="Search by model" />
                <Column field="modification" header={t('modification')} sortable filter filterPlaceholder="Search by Modification" />
            </DataTable>
        </div>
    );
}

export default VehicleDataTable;