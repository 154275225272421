import {VehicleService} from "../services/VehicleService";
import {Button} from "primereact/button";
import React, {useEffect, useState, useRef} from "react";
import {DataView} from "primereact/dataview";
import '../assets/css/DataView.css';

const VehicleDataView = () => {

    const [loading, setLoading] = useState(true);
    const [vehicleList, setVehicleList] = useState([]);
    const isMounted = useRef(false);
    useEffect(() => {
        setTimeout(() => {
            isMounted.current = true;
            setLoading(true);
            const vehicleService = new VehicleService();
            vehicleService.getVehicleList()
                .then(response => {
                    setVehicleList(response.data);
                    setLoading(false);
                });
        }, 1000);
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, [loading]);

    const layout = 'list';

    const itemTemplate = (data) => {
        if (layout === 'list') {
            return (
                <div className="col-12">
                    <div className="product-list-item">
                        <div className="product-list-detail">
                            <div className="product-name">{data.plate}</div>
                            {/*<div className="product-description">{data.make}</div>*/}
                            <span className="product-category">{data.make} {data.model}</span>
                        </div>
                        {/*<hr style={{width: "2px", height: "40px", float: "left", marginRight: "10px"}}/>*/}
                        <div className="product-list-action">
                            {/*<span className="product-price">${data.price}</span>*/}
                            {/*<hr style="width: 1px; height: 20px;"></hr>*/}
                            <Button icon="pi pi-eye"
                                    style={{float: "left"}}></Button>
                            {/*<span className={`product-badge status-${data.inventoryStatus.toLowerCase()}`}>{data.inventoryStatus}</span>*/}
                        </div>
                    </div>
                </div>
            );
        }

        if (layout === 'grid') {
            return (
                <div></div>
                // Grid Content
            );
        }
    }

    return (
        <div className="dataview">
            <div className="vehicled">
                <DataView value={vehicleList} layout={layout} itemTemplate={itemTemplate}
                           paginator rows={10} loading={loading} />
            </div>
        </div>
    );
}

export default VehicleDataView;