import {ViewRow} from "./ViewRow";

export const VehicleViewRow = (props) => {

    return(
        <ViewRow divClasses={"col-12 h-3rem"} title={props.title} titleClasses={"sm:col-4 md:col-4 lg:col-3 xl:col-4 floatLeft"} titleLabelClasses={"text-lg"}
                 value={props.value} valueClasses={"sm:col-8 md:col-8 lg:col-9 xl:col-8 floatRight"}
                 valueLabelClasses={"floatRight font-bold text-2xl"}/>
    )

}
