import {ViewRow} from "./ViewRow";

export const MOTViewRow = (props) => {

    return(
        <ViewRow divClasses={"col-12"} title={props.title} titleClasses={"col-6 floatLeft"} titleLabelClasses={"text-l"}
                 value={props.value} valueClasses={"col-6 floatRight"}
                 valueLabelClasses={"floatRight font-bold text-lg"}/>
    )

}
