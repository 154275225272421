import React from "react";
import VehicleDataTable from "./VehicleDataTable";

export const VehicleList = (props) => {

    return (
        <div className="grid">
            <div className="col-12 xl:col-12">
                <div className="card">
                    <VehicleDataTable />
                </div>
            </div>
        </div>
    );
}