import {PaymentService} from "../services/PaymentService";
import {Button} from "primereact/button";
import React, {useEffect, useState, useRef} from "react";
import '../assets/css/DataTable.css';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {DATE_FORMAT} from "../Constants";
import dateFormat from 'dateformat';
import {useTranslation} from "react-i18next";

const PaymentDataTable = () => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [paymentList, setPaymentList] = useState([]);
    const isMounted = useRef(false);
    useEffect(() => {
        setTimeout(() => {
            isMounted.current = true;
            setLoading(true);
            const paymentService = new PaymentService();
            paymentService.getPaymentList()
                .then(response => {
                    setPaymentList(response.data);
                    setLoading(false);
                });
        }, 1000);
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, [loading]);

    const renderHeader = () => {
        return (
            <div>
                <span className="p-input-icon-left floatLeft addButton">
                    <i className="pi pi-plus" />
                    <Button title="Add payment" icon="pi pi-plus"/>
                </span>
                <div className="flex justify-content-center align-items-center">
                    <h5 className="m-0">{t('payments')}</h5>
                </div>
            </div>
        )
    }

    const layout = 'list';
    const header = renderHeader();

    const dateCreatedBody = (rowData) => {
        return (
            <React.Fragment>
                <span>{dateFormat(rowData.dateCreated, DATE_FORMAT)}</span>
            </React.Fragment>
        );
    }

    const amountBody = (rowData) => {
        return (
            <React.Fragment>
                <span className="floatRight">
                    <strong>{rowData.amount}</strong> {rowData.currency}
                </span>
            </React.Fragment>
        );
    }

    const paymentWayBody = (rowData) => {
        return (
            <React.Fragment>
                <span>
                    {t(rowData.paymentWay)}
                </span>
            </React.Fragment>
        );
    }

    return (
        <div className="grid">
            <div className="col-12 xl:col-12">
                <div className="card">
                    <div>
                        <DataTable value={paymentList} layout={layout} paginator rows={10} loading={loading}
                                   header={header} selectionMode="single" dataKey="paymentId" responsiveLayout="stack"
                                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                   rowsPerPageOptions={[10,25,50]} currentPageReportTemplate={t('paginatorReport')}>
                            <Column header={t("creation_date")} sortable sortField="dateCreated" filter filterPlaceholder="Search by Date"
                                    body={dateCreatedBody}/>
                            <Column header={t("amount")} sortable sortField="amount" filter filterPlaceholder="Search by amount" body={amountBody}/>
                            <Column field="description" header={t("description")} sortable />
                            <Column header={t("payment_way")} body={paymentWayBody} sortable filter filterPlaceholder="Search by type" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentDataTable;