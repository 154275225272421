import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import AppWrapper from "./AppWrapper";
import './i18nextConf';
import ScrollToTop from "./ScrollToTop";

ReactDOM.render(
    <BrowserRouter>
        <ScrollToTop>
            <AppWrapper/>
        </ScrollToTop>
    </BrowserRouter>,
  document.getElementById('root')
);
