
export const ViewRow = (props) => {

    return (
    <div>
        <div className={props.divClasses}>
            <div className={props.titleClasses}>
                <label className={props.titleLabelClasses}>{props.title}</label>
            </div>
            <div className={props.valueClasses}>
                <strong className={props.valueLabelClasses}>{props.value}</strong>
            </div>
        </div>
        <hr/>
    </div>
)
}
