import {MOTViewRow} from "./MOTViewRow";
import {MOTCommentViewRow} from "./MOTCommentViewRow";

export const MOTTestView = (props) => {


    return (
        <div className="flex">
            <div className={`card col-12 border-left-3 ${props.test.testResult === 'FAILED' ? 'border-red-400' : 'border-green-400'}`}>
                {/*<div className="col-6 floatLeft">*/}
                {/*    <label className="text-lg"></label>*/}
                {/*</div>*/}
                {/*<div className="col-6 floatRight">*/}
                {/*    <strong className={`floatRight font-bold */}
                {/*    ${props.test.testResult === 'FAILED' ? 'text-red-400' : 'text-green-400'}`}>{props.test.testResult}</strong>*/}
                {/*</div>*/}

                <MOTViewRow title={"CompletedDate"} value={props.test.completedDate}/>
                <MOTViewRow title={"OdometerValue"} value={props.test.odometerValue}/>
                <MOTViewRow title={"OdometerUnit"} value={props.test.odometerUnit}/>
                <MOTViewRow title={"MOT Test Number"} value={props.test.motTestNumber}/>
                <h4>Comments:</h4>
                {props.test.rfrAndComments.map((comment, cIndex) => (
                    <div key={cIndex} className="col-10">
                        <MOTCommentViewRow title={comment.text} value={comment.type}/>
                    </div>
                ))}
            </div>
        </div>
    )
}


/*
<div key={index}>
                                    <h4>Test {index + 1}</h4>
                                    <ul>
                                        <li>: {}</li>
                                        <li>TestResult: {test.testResult}</li>
                                        <li>ExpiryDate: {test.expiryDate}</li>
                                        <li>OdometerValue: </li>
                                        <li>OdometerUnit: </li>
                                        <li>MOTTestNumber: {test.motTestNumber}</li>

                                    </ul>
                                </div>
 */
