import {UpcomingService} from "../services/UpcomingService";
import {Button} from "primereact/button";
import React, {useEffect, useState, useRef} from "react";
import '../assets/css/DataTable.css';
import {DataTable} from "primereact/datatable";
import { Tooltip } from 'primereact/tooltip';
import {Column} from "primereact/column";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import {DATE_FORMAT} from "../Constants";
import dateFormat from 'dateformat';
import {DEBUG} from "../constants/Constants";
import {useTranslation} from "react-i18next";

const UpcomingDataTable = () => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [upcomingList, setUpcomingList] = useState([]);
    const [selectedUpcoming, setSelectedUpcoming] = useState([]);
    const isMounted = useRef(false);
    const [totalAmount, setTotalAmount] = useState(0);
    useEffect(() => {
        setTimeout(() => {
            isMounted.current = true;
            setLoading(true);
            const upcomingService = new UpcomingService();
            upcomingService.getUpcomingList()
                .then(response => {
                    setUpcomingList(response.data);
                    setLoading(false);
                });
        }, 1000);
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, [loading]);

    const selectionChange = (e) => {
        setSelectedUpcoming(e.value);
        let total = 0;
        for(let upcom of e.value) {
            total += upcom.amount;
        }

        setTotalAmount(total);
    }

    const renderHeader = () => {
        return (
            <div>
                <div className="flex justify-content-center align-items-center">
                    <h5 className="m-0">{t('upcoming_payments')}</h5>
                </div>
            </div>
        )
    }

    const layout = 'list';
    const header = renderHeader();

    const dateCreatedBody = (rowData) => {
        return (
            <React.Fragment>
                <span>{dateFormat(rowData.dateCreated, DATE_FORMAT)}</span>
            </React.Fragment>
        );
    }

    const deadLineBody = (rowData) => {
        let currDate = new Date();
        let soonDate = new Date(currDate);
        soonDate.setDate(soonDate.getDate()+10);
        let deadLine = new Date(rowData.deadLine);
       if (DEBUG){
           console.log("deadLine = " + deadLine);
           console.log("soonDate = " + soonDate);
           console.log("currDate = " + currDate);
       }
       let warnIcon = (soonDate.getTime() > deadLine.getTime()) ?
           <span>
                <Tooltip target=".pi-info-circle" content="Остават по-малко от 10 дни" position="top"/>
                <i className='pi pi-info-circle mr-2'></i>
           </span>
            : "";
        return (
            <React.Fragment>
                <span style={{ color: (currDate.getTime() > deadLine.getTime())
                            ? "red" : (soonDate.getTime() > deadLine.getTime())
                            ? "orange" : "green" }}>
                    <strong>
                        {dateFormat(rowData.deadLine, DATE_FORMAT)} {warnIcon}
                    </strong>
                </span>
            </React.Fragment>
        );
    }

    const amountBody = (rowData) => {
        return (
            <React.Fragment>
                <span className="floatRight" >
                    <strong>{rowData.amount}</strong> {rowData.currency}
                </span>
            </React.Fragment>
        );
    }

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', {style: 'currency', currency: 'BGN'});
    }

    const footerAmountBody = () => {
        return (
            <React.Fragment>
                <span className="floatRight p-inputtext-lg" >
                    <strong>{formatCurrency(totalAmount)}</strong>
                </span>
            </React.Fragment>
        );
    }

    const footerPayBody = () => {
        return (
            <React.Fragment>
                <span className="p-input-icon-left floatLeft">
                    <Button title={t('pay')} label={t('pay')} disabled={selectedUpcoming.length === 0}/>
                </span>
            </React.Fragment>
        );
    }



    const ucTypeBody = (rowData) => {
        return (
            <React.Fragment>
                <span>
                    {t(rowData.ucType)}
                </span>
            </React.Fragment>
        );
    }

    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer={t('total_amount') + " :"} colSpan={3} footerStyle={{textAlign: 'right'}}/>
            <Column footer={footerAmountBody} footerStyle={{textAlign: 'right'}}/>
            <Column footer={footerPayBody} colSpan={2}/>
        </Row>
    </ColumnGroup>;

    return (
        <div className="grid">
            <div className="col-12 xl:col-12">
                <div className="card">
                    <div>
                        <DataTable value={upcomingList} layout={layout} paginator rows={10} loading={loading}
                                   selection={selectedUpcoming} onSelectionChange={selectionChange}
                                   header={header} selectionMode="single" dataKey="upcomingId" responsiveLayout="stack"
                                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                   rowsPerPageOptions={[10,25,50]} currentPageReportTemplate={t('paginatorReport')}
                                   footerColumnGroup={footerGroup}>
                            <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                            <Column header={t("creation_date")} sortable sortField="dateCreated" filter filterPlaceholder="Search by Date"
                                    body={dateCreatedBody}/>
                            <Column header={t("dead_line")} sortable sortField="deadLine" filter filterPlaceholder="Search by validFrom"
                                    body={deadLineBody}/>
                            <Column header={t("amount")} sortable sortField="amount" filter filterPlaceholder="Search by amount" body={amountBody}/>
                            <Column field="description" header={t("description")} sortable />
                            <Column field="ucType" header={t("type")} body={ucTypeBody} sortable filter filterPlaceholder="Search by type" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpcomingDataTable;