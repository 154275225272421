import {RepairService} from "../services/RepairService";
import React, {useEffect, useState, useRef} from "react";
import '../assets/css/DataTable.css';
import {DataTable} from "primereact/datatable";
import { Tooltip } from 'primereact/tooltip';
import {Column} from "primereact/column";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import {DATE_FORMAT} from "../Constants";
import dateFormat from 'dateformat';
import {useTranslation} from "react-i18next";
import Flag from "react-world-flags";

const RepairHistoryDataTable = () => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [upcomingList, setUpcomingList] = useState([]);
    const isMounted = useRef(false);
    const [totalAmount, setTotalAmount] = useState(0);
    useEffect(() => {
        setTimeout(() => {
            isMounted.current = true;
            setLoading(true);
            const repairService = new RepairService();
            repairService.getRepairHistoryDetailList()
                .then(response => {
                    setUpcomingList(response.data);
                    let total = 0;
                    for(let payed of response.data) {
                        total += (payed.amount * (1-(payed.discount/100))) * payed.quantity;
                    }
                    setTotalAmount(total);
                    setLoading(false);
                });
        }, 1000);
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, [loading]);

    const renderHeader = () => {
        return (
            <div>
                <div className="flex justify-content-center align-items-center">
                    <h5 className="m-0">{t('history')} {t('repairs')}</h5>
                </div>
            </div>
        )
    }

    const layout = 'list';
    const header = renderHeader();

    const dateCreatedBody = (rowData) => {
        return (
            <React.Fragment>
                <span>{dateFormat(rowData.dateCreated, DATE_FORMAT)}</span>
            </React.Fragment>
        );
    }

    const serviceBody = (rowData) => {
        return (
            <React.Fragment>
                <span className="floatRight" >
                    {rowData.note}
                </span>
            </React.Fragment>
        );
    }

    const plateBody = (rowData) => {
        return (
            <React.Fragment>
                <span className="plate">
                    <Flag code={rowData.plateCountry} height={12}
                          alt={rowData.plateCountry} title={rowData.plateCountry}/> | <strong>{rowData.plate}</strong>
                </span>
            </React.Fragment>
        );
    }

    const discountedAmountBody = (rowData) => {
        return (
            <React.Fragment>
                <Tooltip target={".discountPrice" + rowData.detailId} content={t('discount') + `: ${rowData.discount}%`} position="top"/>
                <div className={"discountPrice" + rowData.detailId}>
                    <span className="floatRight text-red-500" style={{textDecoration: rowData.discount > 0 ? "line-through" : "none",
                        display: rowData.discount > 0 ? "block" : "none"}}>
                        {(rowData.amount).toLocaleString(undefined, { style: 'currency', currency: rowData.currency })}
                    </span>
                    <span className="floatRight discAmount text-green-600" >
                        {(rowData.amount * (1-(rowData.discount/100)))
                            .toLocaleString(undefined, { style: 'currency', currency: rowData.currency }) }
                    </span>
                </div>
            </React.Fragment>
        );
    }

    const totalBody = (rowData) => {
        return (
            <React.Fragment>
                <span className="floatRight" >
                    <strong>{((rowData.amount * (1-(rowData.discount/100))) * rowData.quantity)
                        .toLocaleString(undefined, { style: 'currency', currency: rowData.currency }) }</strong>
                </span>
            </React.Fragment>
        );
    }

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', {style: 'currency', currency: 'BGN'});
    }

    const footerAmountBody = () => {
        return (
            <React.Fragment>
                <span className="floatRight p-inputtext-lg" >
                    <strong style={{fontSize: "larger"}}>{formatCurrency(totalAmount)}</strong>
                </span>
            </React.Fragment>
        );
    }

    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer={t('total_spent') + " :"} colSpan={7} footerStyle={{textAlign: 'right'}}/>
            <Column footer={footerAmountBody} footerStyle={{textAlign: 'right'}} colSpan={2}/>
        </Row>
    </ColumnGroup>;

    return (
        <div className="grid">
            <div className="col-12 xl:col-12">
                <div className="card">
                    <div>
                        <DataTable value={upcomingList} layout={layout} paginator rows={10} loading={loading}
                                   header={header} selectionMode="single" dataKey="detailId" responsiveLayout="stack"
                                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                   rowsPerPageOptions={[10,25,50,100]} currentPageReportTemplate={t('paginatorReport')}
                                   footerColumnGroup={footerGroup}>
                            <Column header={t("creation_date")} sortable sortField="dateCreated"
                                    filter filterPlaceholder="Search by Date" body={dateCreatedBody}/>
                            <Column field="repairServiceId" header={t("service")} sortable
                                    filter filterPlaceholder="Search by repair service" body={serviceBody}/>
                            <Column header={t("plate")} sortable filter filterPlaceholder="Search by plate"
                                    body={plateBody} style={{width: "11.6%"}}/>
                            <Column field="make" header={t("make")} sortable filter filterPlaceholder="Search by make" />
                            <Column field="model" header={t("model")} sortable filter filterPlaceholder="Search by model" />
                            <Column field="description" header={t("description")} sortable
                                    filter filterPlaceholder="Search by description"/>
                            <Column header={t("quantity")} field="quantity" sortable sortField="quantity"
                                    filter filterPlaceholder="Search by quantity" style={{width: "3%"}}/>
                            {/*<Column header={t("amount")} sortable sortField="amount"*/}
                            {/*        filter filterPlaceholder="Search by amount" body={amountBody} style={{width: "5%"}}/>*/}
                            {/*<Column header={t("discount")} sortable sortField="discount"*/}
                            {/*        filter filterPlaceholder="Search by discount" body={discountBody} style={{width: "4%"}}/>*/}
                            <Column header={t("amount")} field="amount * (1-(discount/100))" sortable sortField="amount * (1-(discount/100))"
                                    filter filterPlaceholder="Search by amount" body={discountedAmountBody} style={{width: "5%"}}/>
                            <Column header={t("total")} body={totalBody} style={{width: "5.5%"}}/>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RepairHistoryDataTable;