import React, {useEffect, useRef, useState} from "react";
import "primereact/resources/themes/tailwind-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import classNames from 'classnames';
import {AppTopbar} from './AppTopbar';
import {AppFooter} from './AppFooter';
import {AppMenu} from './AppMenu';
import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';
import {Register} from "./pages/Register";
import {Login} from "./pages/Login";
import {Dashboard} from "./components/Dashboard";
import {Route, useLocation} from "react-router-dom";
import {VehicleList} from "./components/VehicleList";
import InsuranceDataTable from "./components/InsuranceDataTable";
import UpcomingDataTable from "./components/UpcomingDataTable";
import PaymentDataTable from "./components/PaymentDataTable";
import {useTranslation} from "react-i18next";
import RepairHistoryDataTable from "./components/RepairHistoryDataTable";
import TaxDataTable from "./components/TaxDataTable";
import {MOT} from "./pages/MOT";

export const App = () => {

    const { t } = useTranslation();
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light');
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    let menuClick = false;
    let mobileTopbarMenuClick = false;
    PrimeReact.ripple = true;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const menu = [
        {
            label: 'Home',
            items: [{
                label: t('dashboard'), icon: 'pi pi-fw pi-home', to: '/'
            },{
                label: t('vehicles'), icon: 'pi pi-fw pi-car', to: '/vehicle-list'
            },{
                label: t('insurances'), icon: 'pi pi-fw pi-shield', to: '/insurances'
            },{
                label: t('taxes'), icon: 'pi pi-fw pi-dollar', to: '/taxes'
            },{
                label: t('payments'), icon: 'pi pi-fw pi-money-bill',
                items: [
                    {
                        label: t('upcoming'),
                        icon: 'pi pi-fw pi-money-bill',
                        to: '/upcoming'
                    },
                    {
                        label: t('history'),
                        icon: 'pi pi-fw pi-history',
                        to: '/payments'
                    }
                ]
            },{
                label: t('repairs'), icon: 'pi pi-fw pi-car',
                items: [
                    {
                        label: t('upcoming'),
                        icon: 'pi pi-fw pi-money-bill',
                        to: '/upcoming-repair'
                    },
                    {
                        label: t('history'),
                        icon: 'pi pi-fw pi-book',
                        to: '/repair-history'
                    }
                ]
            },{
                label: t('profile'), icon: 'pi pi-fw pi-user', to: '/profile'
            },{
                label: t('log_out'), icon: 'pi pi-fw pi-sign-out', to: '/logout'
            }]
        }];

    const onWrapperClick = () => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }

    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                       mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                       onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}/>

            <div className="layout-sidebar" onClick={onSidebarClick}>
                <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode}/>
            </div>

            <div className="layout-main-container">
                <div className="layout-main">
                    <Route path="/" exact render={() => <Dashboard colorMode={layoutColorMode} />} />
                    <Route path="/vehicle-list" component={VehicleList} />
                    <Route path="/taxes" component={TaxDataTable} />
                    <Route path="/insurances" component={InsuranceDataTable} />
                    <Route path="/payments" component={PaymentDataTable} />
                    <Route path="/upcoming" component={UpcomingDataTable} />
                    <Route path="/repair-history" component={RepairHistoryDataTable} />
                    <Route path="/login" component={Login} />
                    <Route path="/register" component={Register} />
                    <Route path="/mot-check" component={MOT} />
                </div>
                <AppFooter layoutColorMode={layoutColorMode}/>
            </div>
        </div>
    );
}

export default App;
