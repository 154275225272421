import React from 'react';
import {Route, withRouter} from 'react-router-dom';
import App from './App';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { ForgotPassword } from './pages/ForgotPassword';
import TokenService from "./services/auth.service";
import {MOT} from "./pages/MOT";

const AppWrapper = (props) => {
	if ((props.location.pathname !== '/login' &&
		 props.location.pathname !== '/register' &&
		 props.location.pathname !== '/mot-check' &&
		 props.location.pathname !== '/forgotPassword') && TokenService.getLocalAccessToken() == null){
		return window.location.href = `/login`;
	}

	switch (props.location.pathname) {
		case '/login':
			return <Route path="/login" component={Login}/>
		case '/register':
			return <Route path="/register" component={Register}/>
		case '/mot-check':
			return <Route path="/mot-check" component={MOT}/>
		case '/forgotPassword':
			return <Route path="/forgotPassword" component={ForgotPassword}/>
		case '/logout': {
			return TokenService.logout();
		}
		default:
			return <App/>;
	}
}
export default withRouter(AppWrapper);
