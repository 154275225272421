import { BASE_DOMAIN } from '../constants/Constants';
import authHeader from "./AuthHeader";
import api from "./api";

export class PaymentService {
    getPaymentList(){
        return api.get( BASE_DOMAIN + "api/payment/payment/list", {
            headers:  authHeader()
        });
    }
}