import { BASE_DOMAIN } from '../constants/Constants';
import authHeader from "./AuthHeader";
import api from "./api";

export class RepairService {
    getRepairHistoryContractList(){
        return api.get( BASE_DOMAIN + "api/repair/history/contract/list", {
            headers:  authHeader()
        });
    }
    getRepairHistoryDetailList(){
        return api.get( BASE_DOMAIN + "api/repair/history/detail/list", {
            headers:  authHeader()
        });
    }
}