import { BASE_DOMAIN } from '../constants/Constants';
import authHeader from "./AuthHeader";
import api from "./api";

export class UpcomingService {
    getUpcomingList(){
        return api.get( BASE_DOMAIN + "api/payment/upcoming/list", {
            headers:  authHeader()
        });
    }
}