import { BASE_DOMAIN } from '../constants/Constants';
import authHeader from "./AuthHeader";
import api from "./api";

export class InsuranceService {
    getInsuranceList(){
        return api.get( BASE_DOMAIN + "api/insurance/list", {
            headers:  authHeader()
        });
    }
}